<template>
  <div>
    <div class="row">
      <div class="col-6 float-right">
        <h3>
          Manage POS Reservations
        </h3>
      </div>
      <div class="col-6">
        <button
          class="btn btn-primary float-right"
          type="button"
          @click="addNewReservations"
        >
          Add New Reservations
          <i class="fas fa-plus-circle ml-1"></i>
        </button>
      </div>
    </div>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.posReservationSearch"
    />
    <ValidationObserver ref="posReservationSearch">
      <div class="card my-3">
        <div class="card-header">
          <div class="card-title mb-0">Search</div>
        </div>
        <div class="card-body">
          <form @submit.prevent="getReservations">
            <div class="row">
              <div class="col-lg-2">
                <TextInput
                  name="Order Number"
                  id="orderNumber"
                  rules="numeric|max:7"
                  v-model="searchRequest.orderNumber"
                />
              </div>
              <div class="col-lg-4">
                <ValidationProvider
                  name="Select Park"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label
                    @click="$refs.select.focus()"
                    :class="{ error: errors[0] }"
                    for="locationId"
                  >
                    Select Park
                  </label>
                  <select
                    class="form-control form-control-lg"
                    id="locationId"
                    v-model="searchRequest.locationId"
                    v-bind="ariaInput"
                  >
                    <option
                      v-for="location in locations"
                      :key="location.id"
                      :value="location.id"
                      >{{ location.name }}</option
                    >
                  </select>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
              </div>
              <div class="col-lg-4">
                <ValidationProvider
                  name="Product Class"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label
                    @click="$refs.select.focus()"
                    :class="{ error: errors[0] }"
                    for="productClassificationId"
                  >
                    Product Class
                  </label>
                  <select
                    class="form-control form-control-lg"
                    id="productClassificationId"
                    v-model="searchRequest.productClassificationId"
                    v-bind="ariaInput"
                  >
                    <option
                      v-for="p in productClasses"
                      :key="p.id"
                      :value="p.id"
                      >{{ p.name }}</option
                    >
                  </select>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
              </div>
            </div>
            <div class="row my-3">
              <div class="col-lg-12">
                <button class="btn btn-primary float-left" type="submit">
                  Search
                  <i class="fas fa-search ml-1"></i>
                </button>
                <button
                  type="button"
                  class="btn ml-2 mb-2"
                  @click="resetSearch"
                >
                  RESET
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ValidationObserver>
    <div class="card my-3">
      <div class="card-header">
        <div class="card-title mb-0">Results</div>
      </div>
      <div class="card-body">
        <div class="col-lg-12">
          <div>
            <b-table
              ref="table"
              striped
              :fields="fields"
              :items="results"
              empty-text="No Reservation Results..."
              empty-filtered-text="No Reservation Results..."
              show-empty
              stacked="xl"
              no-local-sorting
              :current-page="currentPage"
              :per-page="perPage"
              small
              bordered
              sort-icon-left
              :busy="resultsLoading"
            >
              <template #table-busy>
                <div class="text-center my-2">
                  <span
                    class="spinner-border spinner-border-sm align-middle"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
              </template>
              <template v-slot:cell(balance)="data">
                <span :class="data.item.balance < 0 ? 'red' : ''">{{
                  data.item.balance | formatCurrency
                }}</span>
              </template>
              <template v-slot:cell(id)="data">
                <i
                  class="fas fa-edit editIcon mr-2"
                  @click="editOrder(data.item.id)"
                ></i>
                <i
                  class="fas fa-print editIcon"
                  @click="print(data.item.id)"
                ></i>
              </template>
            </b-table>
            <div class="d-flex">
              <div class="mr-auto">
                Showing
                {{ perPage * currentPage - perPage + (totalRows > 0 ? 1 : 0) }}
                to {{ perPageDisplay }} of {{ totalRows }} entries
              </div>
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "@/validation/TextInput.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import AdminLookupService from "@/services/admin/AdminLookupService.js";
import AdminProductManagementService from "@/services/admin/AdminProductManagementService.js";
import AdminPointOfSaleService from "@/services/admin/AdminPointOfSaleService.js";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";

export default {
  name: "AdminPosReservations",
  title: "Admin - POS Reservations",
  mixins: [checkPermissionMixin],
  components: {
    TextInput,
    ValidationObserver,
    ValidationProvider,
    FormErrorAlert
  },
  data() {
    return {
      searchRequest: {
        orderNumber: null,
        locationId: null,
        productClassificationId: null,
        tenantId: this.tenatId
      },
      locations: [{ name: "All", id: null }],
      productClasses: [{ name: "All", id: null }],
      fields: [
        { key: "orderNumber", label: "Order Number", class: "colWidth" },
        {
          key: "dateCreated",
          label: "Date Entered",
          formatter: "formatDate",
          class: "colWidth"
        },
        {
          key: "createdBy",
          label: "Created By",
          class: "colWidth"
        },
        {
          key: "dateLastModified",
          label: "Last Modified",
          formatter: "formatDate",
          class: "colWidth"
        },
        { key: "lastModifiedBy", label: "Last Modified By", class: "colWidth" },
        { key: "notes", class: "notesWidth" },
        {
          key: "orderTotal",
          label: "Total",
          formatter: value => {
            return this.$options.filters.formatCurrency(value);
          },
          class: "colWidth"
        },
        {
          key: "paymentTotal",
          label: "Payments",
          formatter: value => {
            return this.$options.filters.formatCurrency(value);
          },
          class: "colWidth"
        },
        {
          key: "balance",
          class: "colWidth"
        },
        { key: "id", label: "", class: "iconColWidth" }
      ],
      resultsLoading: false,
      results: [],
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      errors: []
    };
  },
  methods: {
    addNewReservations() {
      this.$router.push("/admin/add-pos-reservations").catch(() => {});
    },
    editOrder(orderId) {
      this.$router
        .push(`/admin/edit-pos-reservations?id=${orderId}&p=false`)
        .catch(() => {});
    },
    print(orderId) {
      this.$router
        .push(`/admin/edit-pos-reservations?id=${orderId}&p=true`)
        .catch(() => {});
    },
    async getReservations() {
      this.resultsLoading = true;
      this.$refs.posReservationSearch.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(
              this.$refs.posReservationSearch.errors
            )
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.posReservationSearch.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          const service = new AdminPointOfSaleService(this.tenantId);
          const response = await service.getPointOfSaleOrders(
            this.searchRequest
          );
          if (response?.statusCode === "Success") {
            this.results = response.data;
          } else {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
          }
          this.resultsLoading = false;
          this.errors = [];
        }
      });
    },
    async getLocations() {
      const lookupService = new AdminLookupService(this.tenantId);
      const locations = await lookupService.getLocations(this.tenantId);
      const filteredLocations = locations.filter(x =>
        this.checkLocationPermission("ReservationManagementAdd", x.id)
      );
      this.locations = [...this.locations, ...filteredLocations];
    },
    async getProductClasses() {
      const service = new AdminProductManagementService(this.tenantId);
      const classes = await service.getAllProductClasses();
      this.productClasses = [...this.productClasses, ...classes];
    },
    resetSearch() {
      this.searchRequest = {
        orderNumber: null,
        locationId: null,
        productClassificationId: null
      };
      this.getReservations();
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    totalRows() {
      return this.results.length;
    },
    perPageDisplay() {
      if (this.perPage * this.currentPage > this.totalRows) {
        return this.totalRows;
      }
      if (this.totalRows > this.perPage) {
        return this.perPage;
      }
      return this.totalRows;
    }
  },
  mounted() {
    this.getReservations();
    this.getLocations();
    this.getProductClasses();
  }
};
</script>

<style>
.editIcon {
  cursor: pointer;
  color: #1078a8;
}
.colWidth {
  width: 120px !important;
}
.notesWidth {
  width: 650px !important;
}
.red {
  color: #e00000;
}
.iconColWidth {
  width: 90px !important;
}
</style>
